import React, { useEffect, useState } from 'react';
import api from '../axios/api';
import { FaMapMarkerAlt, FaBuilding, FaBullseye, FaTag, FaClock, FaTrophy } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
// EventItem Component
const EventItem = ({ imageSrc, title, venue, duration, organizers, tags, targetSet, targetAchieved, details, project_id, esgGoals }) => {
  return (
    <div className="event__item mb-30 wow fadeInUp">
      <div className="row g-5 g-lg-0 align-items-center">
        <div className="col-lg-4">
          <div className="event__image">
            <a rel="noreferrer" target="_blank" href={`/project/${project_id}`}>
              <img src={imageSrc || "assets/images/default-image.jpg"} alt="event" />
            </a>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="event__content">
            <div>
              <h3><a rel="noreferrer" target="_blank" href={`/project/${project_id}`}>{title}</a></h3>
            </div>
            <div ><span style={{ color: 'black' }} className="clsTitle"><FaMapMarkerAlt style={{ display: 'inline-block' }} color="#3F6B33" /> Venue : </span> {venue}</div>
            <div><span style={{ color: 'black' }} className="clsTitle"><FaClock style={{ display: 'inline-block' }} color="#3F6B33" /> Duration : </span> {duration}</div>
            <div><span style={{ color: 'black' }} className="clsTitle"><FaBuilding style={{ display: 'inline-block' }} color="#3F6B33" /> Organized By : </span> {organizers}</div>
            <div><span style={{ color: 'black' }} className="clsTitle"><FaTag style={{ display: 'inline-block' }} color="#3F6B33" /> Tags : </span> {tags}</div>
            <div><span style={{ color: 'black' }} className="clsTitle"><FaBullseye style={{ display: 'inline-block' }} color="#3F6B33" /> Target Set : </span> {targetSet} Trees <FaTrophy style={{ display: 'inline-block' }} color='#3F6B33' /> Target Achieved: {targetAchieved} Trees</div>
            <div style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              <p>{details}</p>
            </div>
            <div
              className="share-buttons flex flex-wrap items-center gap-2"
              style={{ justifyContent: "flex-start" }}
            >
              {esgGoals.split(",").map((goal) => (
                <img
                  key={goal.trim()}
                  src={`/images/EsgGoals/${goal.trim().replaceAll(" ", "-")}.jpg`}
                  alt={goal.trim()}
                  className="h-20 w-20 object-contain"
                  data-tooltip-id="Esg-Goals"
                  data-tooltip-content={goal}
                />
              ))}
              <Tooltip id="Esg-Goals" place="top" effect="solid" />
            </div>
            <div>
              <a rel="noreferrer" target="_blank" href={`/project/${project_id}`}>View More Info</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetching data from the API
    api.get('/api/project/getallprojects')
      .then(response => {
        if (response.data.success) {
          setProjects(response.data.projects);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the projects!", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {/* Page banner area */}
      <section className="bg-image pt-40 pb-40">
        <div className="container">
          <h2 className="wow fadeInUp">Plantation Projects</h2>
        </div>
      </section>

      {/* Event area */}
      <div className="event pt-60 pb-60">
        <div className="container">
          {/* Loop through projects and render EventItem */}
          {projects.map((project) => (
            <EventItem
              key={project.project_id}
              project_id={project.project_id}
              imageSrc={project.project_cover_image}  // Fallback to default if null
              title={project.name}
              venue={project.address}
              duration={`${project.startDate} To ${project.endDate}`}
              organizers={project.organizationName}
              tags={project.tags}  // This might be multiple, modify if needed
              targetSet={project.target}
              targetAchieved={project.total_trees_planted}
              details={project.description}
              esgGoals={project.esgGoals}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Projects;
