import "../assets/NewFiles/Css/ProjectPage.css";
import React, { useEffect, useState, useCallback } from "react";
import { Line } from "react-chartjs-2";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FaTree, FaBullseye, FaHashtag } from "react-icons/fa";
import api from "../axios/api";
import { useParams } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
} from "react-leaflet";
import {
  FaMapMarkerAlt,
  FaBuilding,
  FaRegClock,
} from "react-icons/fa";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import {
  FaInstagram,
} from "react-icons/fa";
import MapToggle from "../components/common/MapToggle";
import { Tooltip } from "react-tooltip";
import MapSpeciesFilter from "../components/projectPage/MapSpeciesFilter";
import CustomClusterGroup from "../components/projectPage/CustomClusterGroup";
import noImage from "../assets/images/noImage.jpg"

const mockData = {
  treesByMonth: {
    labels: [
      "2021-1",
      "2021-6",
      "2021-11",
      "2022-4",
      "2022-9",
      "2023-2",
      "2023-7",
      "2023-12",
      "2024-5",
    ],
    data: [300, 3200, 700, 900, 800, 400, 300, 500, 200],
  },
  co2OffsetByMonth: {
    labels: [
      "2021-1",
      "2021-6",
      "2021-11",
      "2022-4",
      "2022-9",
      "2023-2",
      "2023-7",
      "2023-12",
      "2024-5",
    ],
    data: [20, 40, 80, 120, 160, 220, 300, 380, 460],
  },
  position: [20.5937, 78.9629],
  averageWeeklyTrees: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    data: [120, 150, 180, 130, 160, 90, 110],
  },
};

const ProjectPage = () => {
  const { projectid } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [treeMapData, setTreeMapData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [apiStats, setApiStats] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState(null);

  const [speciesData, setSpeciesData] = useState({});
  const [filteredTreeMapData, setFilteredTreeMapData] = useState([]);

  const processSpeciesData = (trees) => {
    const speciesGroups = trees.reduce((acc, tree) => {
      const species = tree.tree_species_label;
      if (!acc[species]) {
        acc[species] = {
          count: 0,
          icon: tree.species_icon_url || '/images/icon/leaf.png'
        };
      }
      acc[species].count++;
      return acc;
    }, {});
    setSpeciesData(speciesGroups);
    setFilteredTreeMapData(trees);
  };

  const groupByWeek = (trees) => {
    const weeklyData = {};
    trees.forEach((tree) => {
      const date = new Date(tree.plantedOn);
      const weekStart = new Date(date.setDate(date.getDate() - date.getDay()));
      const weekKey = weekStart.toISOString().split("T")[0];
      weeklyData[weekKey] = (weeklyData[weekKey] || 0) + 1;
    });
    return weeklyData;
  };

  const groupByMonth = (trees) => {
    const monthlyData = {};
    trees.forEach((tree) => {
      const date = new Date(tree.plantedOn);
      const monthKey = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      monthlyData[monthKey] = (monthlyData[monthKey] || 0) + 1;
    });
    return monthlyData;
  };

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await api.get(
          `api/project/getProjectDetails/${projectid}`
        );
        if (response.data.success) {
          setProjectData(response.data);
          const formattedImages = response.data.galleryImages.map((image) => ({
            src: image.imageUrl,
            thumbnail: image.imageUrl,
            caption: image.imageDescription,
            thumbnailWidth: 320,
            thumbnailHeight: 212,
          }));
          setImages(formattedImages);
          setTreeMapData(response.data.Trees);
          processTreeData(response.data.Trees);
          setApiStats(response.data.TreeStats);
          processSpeciesData(response.data.Trees);
          // Parse and set GeoJSON data
          if (response.data.project.area_geojson) {
            try {
              const parsedGeoJson = JSON.parse(
                response.data.project.area_geojson
              );
              setGeoJsonData(parsedGeoJson);
            } catch (error) {
              console.error("Error parsing GeoJSON:", error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectDetails();
  }, [projectid]);

  const handleSpeciesFilter = (selectedSpecies) => {
    if (selectedSpecies === 'all') {
      setFilteredTreeMapData(treeMapData);
    } else {
      setFilteredTreeMapData(
        treeMapData.filter(tree => tree.tree_species_label === selectedSpecies)
      );
    }
  };

  // GeoJSON style options
  const geoJsonStyle = {
    fillColor: "#8BC34A",
    fillOpacity: 0.2,
    color: "#8BC34A",
    weight: 2,
  };

  // Function to fit map bounds to GeoJSON
  const onGeoJsonLoad = (e) => {
    const bounds = e.target.getBounds();
    e.target._map.fitBounds(bounds);
  };

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const img = new Image();
      img.src = images[currentImage].src;
      img.onload = handleImageLoad;
      img.onerror = () => {
        console.error("Error loading image:", images[currentImage].src);
        setIsImageLoaded(true); // Set to true even on error to remove loading state
      };
    } else {
      setIsImageLoaded(false);
    }
  }, [isOpen, currentImage, images, handleImageLoad]);

  const processTreeData = (trees) => {
    // Sort trees by plantedOn date
    const sortedTrees = trees.sort(
      (a, b) => new Date(a.plantedOn) - new Date(b.plantedOn)
    );

    if (sortedTrees.length === 0) {
      setChartData(null);
      return;
    }

    const startDate = new Date(sortedTrees[0].plantedOn);
    const endDate = new Date(sortedTrees[sortedTrees.length - 1].plantedOn);
    const daysDifference = (endDate - startDate) / (1000 * 60 * 60 * 24);

    const isMonthly = daysDifference > 49; // If more than 7 weeks, use monthly view

    const groupedData = isMonthly
      ? groupByMonth(sortedTrees)
      : groupByWeek(sortedTrees);

    const labels = Object.keys(groupedData);
    const data = Object.values(groupedData);

    setChartData({
      labels,
      datasets: [
        {
          label: "Trees Planted",
          data,
          fill: false,
          backgroundColor: "#8BC34A",
          borderColor: "#8BC34A",
        },
      ],
    });
  };

  // Define the months dynamically if needed or keep them fixed
  const months = apiStats.map((stat) => stat.registration_month) || [
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
  ];

  const treeStatsData = {
    labels: months,
    datasets: [
      {
        label: "Total Trees Registered",
        borderColor: "#4CAF50",
        data: apiStats.map((stat) => stat.total_trees_registered),
        tension: 0.1,
      },
    ],
  };
  if (!projectData) return <div>Loading...</div>;

  const { project } = projectData;

  return (
    <div className="project-page">
      {project.project_cover_image && (
        <div
          className="cover-image"
          style={{
            backgroundImage: `url(${project.project_cover_image})`,
            height: "250px",
          }}
        ></div>
      )}

      <div className="project-info-container">
        <div className="project-header">
          <h3 className="project-name">{project.name}</h3>
          <div className="tree-targets">
            <div className="target-btn">
              <FaBullseye />{" "}
              <span>
                <strong>Total Target: {project.target}</strong>
              </span>
            </div>
            <div className="target-btn">
              <FaTree />{" "}
              <span>
                <strong>Trees Planted: {project.total_trees_planted}</strong>
              </span>
            </div>
          </div>
        </div>

        <div className="project-description">
          <p className="flex items-center">
            <FaRegClock className="mr-1 text-blue-600" />
            Duration: <span className="font-normal ml-1 text-black">{project.startDate} To {project.endDate}</span>
          </p>
          <p className="flex items-center">
            <FaBuilding className="mr-1 text-blue-600" />
            Participants: <span className="font-normal ml-1 text-black">{project.organizationName}</span>
          </p>
          <p className="flex items-center">
            <FaMapMarkerAlt className="mr-1 text-blue-600" />
            Venue: <span className="font-normal ml-1 text-black">{project.address}</span>
          </p>
          <p className="flex items-center">
            <FaHashtag className="mr-1 text-blue-600" />
            Tags: <span className="font-normal ml-1 text-black">{project.tags?.split(",").map((tag) => ` #${tag.trim()}`).join(",")}</span>
          </p>
          <h3 className="project-name">SDG Goals Achieved </h3>
          <div
            className="share-buttons flex flex-wrap items-center gap-2 pt-2 pb-2"
            style={{ justifyContent: "flex-start" }}
          >
            {project.esgGoals.split(",").map((goal) => (
              <img
                key={goal.trim()}
                src={`/images/EsgGoals/${goal.trim().replaceAll(" ", "-")}.jpg`}
                alt={goal.trim()}
                className="h-20 w-20 object-contain"
                data-tooltip-id="Esg-Goals"
                data-tooltip-content={goal}
              />
            ))}
            <Tooltip id="Esg-Goals" place="top" effect="solid" />
          </div>

          <div
            className="share-buttons"
            style={{ justifyContent: "flex-start" }}
          >
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <button
              onClick={() => {
                navigator.clipboard
                  .writeText(window.location.href)
                  .then(() => {
                    const shareUrl = `https://www.instagram.com/`;
                    window.open(shareUrl, "_blank");
                  })
                  .catch((err) => {
                    console.error("Failed to copy: ", err);
                  });
              }}
              style={{
                height: "32px",
                width: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                padding: "5px",
                backgroundColor: "#D93F7C",
              }}
            >
              <FaInstagram size={32} />
            </button>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={window.location.href}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <h3 className="project-name" style={{ marginBottom: "5px" }}>
            Project Description
          </h3>
          <span className="text-black"
            dangerouslySetInnerHTML={{
              __html: project.description.replace(/\r?\n/g, "<br/>"),
            }}
          />
          {/* <p
            dangerouslySetInnerHTML={{
              __html: project.description.replace(/\r?\n/g, "<br/>"),
            }}
          /> */}
        </div>
      </div>

      <div className="content-section">
        <div className="image-gallery">
          <h3 className="project-name">Project Images</h3>
          {images.length > 0 ? (
            <Gallery
              thumbnailStyle={() => ({
                border: "1px solid black",
                height: "100%",
                width: "100%",
              })}
              images={images}
              enableImageSelection={false}
              onClick={(index) => {
                setCurrentImage(index);
                setIsOpen(true);
              }}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full w-full">
              <p className="text-gray-500">No Gallery images available</p>
              <img src={noImage} alt="No image" className="w-24 h-24 object-contain opacity-70" />
            </div>
          )}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[currentImage].src}
            nextSrc={images[(currentImage + 1) % images.length].src}
            prevSrc={
              images[(currentImage + images.length - 1) % images.length].src
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => {
              setCurrentImage(
                (currentImage + images.length - 1) % images.length
              );
              setIsImageLoaded(false);
            }}
            onMoveNextRequest={() => {
              setCurrentImage((currentImage + 1) % images.length);
              setIsImageLoaded(false);
            }}
            imageCaption={images[currentImage].caption}
            onImageLoad={handleImageLoad}
            reactModalStyle={{
              overlay: { zIndex: 1500 },
            }}
          />
        )}

        <div className="tree-graph">
          <h3 className="project-name">Trees Planted</h3>
          {chartData ? (
            <Line data={treeStatsData} />
          ) : (
            <p>No tree planting data available</p>
          )}
        </div>
      </div>

      <div className="Map-Container p-2">
        <h2 className="p-2 text-center"> Digital Biodiversity Map</h2>
        <MapContainer
          center={mockData.position}
          zoom={4}
          style={{ height: "100vh", width: "100%" }}
        >
          <MapSpeciesFilter
            speciesData={speciesData}
            onFilterChange={handleSpeciesFilter}
          />
          <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
          {geoJsonData && (
            <GeoJSON
              data={geoJsonData}
              style={geoJsonStyle}
              onEachFeature={(feature, layer) => {
                layer.on({
                  load: onGeoJsonLoad,
                });
              }}
            />
          )}
          <MapToggle />
          <CustomClusterGroup
            trees={filteredTreeMapData}
            speciesData={speciesData}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default ProjectPage;
