import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import {
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl
} from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  const [chartData, setChartData] = useState({});
  const [selectedChartFilter, setSelectedChartFilter] = useState('Yearly');
  // const [selectedTableYear, setSelectedTableYear] = useState(new Date().getFullYear());
  const [selectedTableYear, setSelectedTableYear] = useState(null);
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/upload/statistics`);
        const processedData = processApiData(response.data);
        setChartData(processedData);

        if (response.data.allYearsMonthly.length > 0) {
          const years = response.data.allYearsMonthly.map(item => item.year);
          const latestYear = Math.max(...years);
          setSelectedTableYear(latestYear);
        }
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };
    fetchData();
  }, []);

  const processApiData = (apiData) => {
    // Process monthly data for yearly view
    const yearlyData = {};
    apiData.allYearsMonthly.forEach(item => {
      if (!yearlyData[item.year]) {
        yearlyData[item.year] = Array(12).fill(0);
      }
      yearlyData[item.year][item.month - 1] = item.count;
    });

    const weeklyData = Array(7).fill(0);
    const dayToIndex = {
      'Monday': 0, 'Tuesday': 1, 'Wednesday': 2, 'Thursday': 3,
      'Friday': 4, 'Saturday': 5, 'Sunday': 6
    };
    apiData.thisWeekDaily.forEach(item => {
      weeklyData[dayToIndex[item.day]] = item.count;
    });
  
    // Process yesterday's hourly data - now includes all hours with 0 for no data
    const yesterdayData = Array(24).fill(0);
    apiData.yesterdayHourly.forEach(item => {
      yesterdayData[item.hour] = item.count;
    });
  
    // Process this month's weekly data - now includes all weeks with 0 for no data
    const monthlyWeekData = Array(5).fill(0);
    apiData.thisMonthWeekly.forEach(item => {
      monthlyWeekData[item.week - 1] = item.count;
    });

    return {
      yearlyData,
      weeklyData,
      yesterdayData,
      monthlyWeekData
    };
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Filter for chart data
  const filters = {
   'Yesterday': {
    labels: Array.from({ length: 24 }, (_, i) => 
      `${String(i).padStart(2, '0')}:00`
    ),
    datasets: [{
      label: 'Yesterday',
      data: chartData.yesterdayData || [],
      fill: false,
      borderColor: 'rgba(255,99,132,1)',
      tension: 0.1
    }]
    },
    'Weekly': {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [{
        label: 'This Week',
        data: chartData.weeklyData || [],
        fill: false,
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.1
      }]
    },
    'Monthly': {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      datasets: [{
        label: 'This Month',
        data: chartData.monthlyWeekData || [],
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1
      }]
    },
    'Yearly': {
      labels: monthNames,
      datasets: chartData.yearlyData ? 
        Object.entries(chartData.yearlyData).map(([year, data]) => ({
          label: `Year ${year}`,
          data: data,
          fill: false,
          borderColor: getRandomColor(),
          tension: 0.1
        })) : []
    }
  };

  const handleChartFilterChange = (event) => {
    setSelectedChartFilter(event.target.value);
  };

  const handleTableYearChange = (event) => {
    setSelectedTableYear(event.target.value);
  };

  const currentChartData = filters[selectedChartFilter];
  
  // Get available years for table
  const availableYears = chartData.yearlyData ? Object.keys(chartData.yearlyData) : [];
  const tableData = chartData.yearlyData?.[selectedTableYear] || Array(12).fill(0);

  return (
    <Box
      sx={{
        margin: '50px',
        border: '1px solid',
        padding: '50px',
        borderRadius: '10px',
        '& .MuiTableCell-root': {
          padding: '8px',
        }
      }}
    >
      {/* Chart Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        margin: '20px 0' 
      }}>
        <FormControl>
          <Select
            value={selectedChartFilter}
            onChange={handleChartFilterChange}
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                padding: '10px',
                fontSize: '16px',
              }
            }}
          >
            <MenuItem value="Yesterday">Yesterday</MenuItem>
            <MenuItem value="Weekly">This Week</MenuItem>
            {/* <MenuItem value="Monthly">This Month</MenuItem> */}
            <MenuItem value="Yearly">Yearly</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ width: '100%', height: '500px' }}>
        <Line data={currentChartData} options={{
          responsive: true,
          plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Data Visualization' }
          },
          maintainAspectRatio: false,
        }} />
      </Box>

      {/* Table Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        margin: '50px 0',
      }}>
        <FormControl>
          <Select
            value={selectedTableYear}
            onChange={handleTableYearChange}
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                padding: '10px',
                fontSize: '16px',
              }
            }}
          >
            {availableYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <TableContainer 
          component={Paper} 
          sx={{ 
            width: '80%', 
            '& .MuiTableCell-root': {
              textAlign: 'center'
            }
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: '#002F43' }}>
              <TableRow>
                <TableCell sx={{ color: '#fff' }}>Months {selectedTableYear}</TableCell>
                <TableCell sx={{ color: '#fff' }}>Plants</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {monthNames.map((monthLabel, index) => (
                <TableRow key={index}>
                  <TableCell>{monthLabel}</TableCell>
                  <TableCell>{tableData[index] || '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Chart;