import React, { useState, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { 
  Select, 
  MenuItem, 
  FormControl,
  Paper,
  Typography,
  Box
} from '@mui/material';

const MapSpeciesFilter = ({ speciesData, onFilterChange }) => {
  const [selectedSpecies, setSelectedSpecies] = useState('all');
  const map = useMap();

  // Disable map interaction when the select is open
  useEffect(() => {
    const selectContainer = document.querySelector('.species-select-container');
    if (selectContainer) {
      selectContainer.addEventListener('mouseenter', () => {
        if (map) {
          map.dragging.disable();
          map.scrollWheelZoom.disable();
        }
      });
      
      selectContainer.addEventListener('mouseleave', () => {
        if (map) {
          map.dragging.enable();
          map.scrollWheelZoom.enable();
        }
      });
    }

    return () => {
      if (map) {
        map.dragging.enable();
        map.scrollWheelZoom.enable();
      }
    };
  }, [map]);

  const handleSpeciesChange = (event) => {
    const value = event.target.value;
    setSelectedSpecies(value);
    onFilterChange(value);
  };

  // Stop event propagation to prevent map interaction
  const handleSelectClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box 
      className="species-select-container"
      onClick={handleSelectClick}
      sx={{
        position: 'absolute',
        bottom: 10,
        left: 10,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Paper 
        elevation={3}
        sx={{
          p: 2,
          background: 'linear-gradient(to bottom, #fafafa, #f5f5f5)',
          border: '1px solid #e0e0e0'
        }}
      >
        <FormControl 
          fullWidth 
          sx={{ 
            mb: 2,
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#7EA10D',
              '&:hover': {
                backgroundColor: '#8FBF26'
              },
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }
            }
          }}
        >
          <Select
            value={selectedSpecies}
            onChange={handleSpeciesChange}
            displayEmpty
            sx={{ minWidth: 240 }}
            size='small'
            renderValue={(selected) => (selected === "all" ? "Biodiversity Filter" : selected)}
          >
            <MenuItem value="all">All Species</MenuItem>
            {Object.entries(speciesData).map(([species, data]) => (
              <MenuItem 
                key={species} 
                value={species}
                sx={{
                  '&:hover': {
                    backgroundColor: '#8FBF26'
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#7EA10D'
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <img 
                    src={data.icon || '/images/icon/leaf.png'} 
                    alt={species}
                    style={{ width: 20, height: 20, objectFit: 'contain' }}
                  />
                  <span style={{color: '#000000'}}>{species}</span>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="legend">
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              maxHeight: 600,
              minHeight:600,
              overflowY: 'auto',
              pr: 1
            }}
          >
            {Object.entries(speciesData).map(([species, data]) => (
              <Box 
                key={species}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  p: 1,
                  borderRadius: 1,
                  backgroundColor: '#f5f5f5',
                  '&:hover': {
                    backgroundColor: '#e3f2fd'
                  },
                  transition: 'background-color 0.2s'
                }}
              >
                <Box 
                  sx={{
                    width: 32,
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: 1,
                    p: 0.5
                  }}
                >
                  <img 
                    src={data.icon || '/images/icon/leaf.png'}
                    alt={species}
                    style={{ width: 24, height: 24, objectFit: 'contain' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {species}
                  </Typography>
                  <Typography variant="caption" color="text.secondary" className='mt-1'>
                    {data.count} trees
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default MapSpeciesFilter;