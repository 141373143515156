import React, { useEffect, useState, useContext } from "react";
import api from "../../axios/api.js";
import "../../assets/NewFiles/Css/IndividualProjectDashboard.css";
import { useParams } from "react-router-dom";
import PopupMap from "../features/PopupMap.jsx";
import { GlobalContext } from "../../contexts/GlobalContext";
import TreePlantationCoverPic from '../../assets/images/newassets/treeplantationCoverPic.jpg';
import imageCompression from "browser-image-compression";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TagsInput from "react-tagsinput";

const EditProjectPage = () => {
  const {
    address,
    setAddress,
    latlng,
    setLatlng,
    country,
    addressData,
    role,userId,isLoggedIn
  } = useContext(GlobalContext);
  const navigate=useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [organizationsOptions, setOrganizationsOptions] = useState([]);
  const [project, setProject] = useState({});
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOrganizations,setSelectedOrganizations]=useState([]);
  const [tags,setTags]=useState([]);
  const [selectedGoals,setSelectedGoals]=useState([]);
  const [invalidFields, setInvalidFields] = useState([]);
  const { id } = useParams();
  const [esgOptions] = useState([
    { index: 1, value: "No Poverty", label: "No Poverty" },
    { index: 2, value: "Zero Hunger", label: "Zero Hunger" },
    {
      index: 3,
      value: "Good Health And Well Being",
      label: "Good Health And Well Being", // Fixed typo from "lable"
    },
    { index: 4, value: "Quality Education", label: "Quality Education" },
    { index: 5, value: "Gender Equality", label: "Gender Equality" },
    {
      index: 6,
      value: "Clean Water And Sanitation",
      label: "Clean Water And Sanitation",
    },
    {
      index: 7,
      value: "Affordable And Clean Energy",
      label: "Affordable And Clean Energy",
    },
    {
      index: 8,
      value: "Decent Work And Economic Growth",
      label: "Decent Work And Economic Growth",
    },
    {
      index: 9,
      value: "Industry-Innovation and Infrastructure",
      label: "Industry, Innovation and Infrastructure", // Adjusted spacing
    },
    { index: 10, value: "Reduced Inequalities", label: "Reduced Inequalities" },
    {
      index: 11,
      value: "Sustainable Cities And Communities",
      label: "Sustainable Cities And Communities",
    },
    {
      index: 12,
      value: "Responsible Consumption And Production",
      label: "Responsible Consumption And Production",
    },
    { index: 13, value: "Climate Action", label: "Climate Action" },
    { index: 14, value: "Life Below Water", label: "Life Below Water" },
    { index: 15, value: "Life On Land", label: "Life On Land" },
    {
      index: 16,
      value: "Peace-Justice and Strong Institutions",
      label: "Peace, Justice and Strong Institutions", // Adjusted spacing
    },
    {
      index: 17,
      value: "Partnerships For The Goals",
      label: "Partnerships For The Goals",
    },
  ]);
  
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    component: null
  });

  // Function to handle modal opening with different components
  const openModal = (title, component) => {
    setModalConfig({
      isOpen: true,
      title,
      component
    });
  };

  // Function to handle modal closing
  const closeModal = () => {
    setModalConfig({
      isOpen: false,
      title: "",
      component: null
    });
  };

  const renderComponent = () => {
    const Component = modalConfig.component;
    return Component ? <Component onClose={closeModal} /> : null;
  };

  const compressImage = async(file)=>{
    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    return compressedFile
  }

  
  // Fetch organizations and project details
  useEffect(() => {
    const fetchOrganizationsAndProject = async () => {
      try {
        const orgResponse = await api.get("/api/user/organizations");
        if (orgResponse.data && orgResponse.data.length > 0) {
          const orgOptions = orgResponse.data[0].map((org) => ({
            value: org.id,
            label: org.company_name,
            created_by_user: org.created_by_user,
          }));

          const filteredOptions = (role === 'corporate' || role === "ngo")
            ? orgOptions.filter((organization) => organization.created_by_user === userId)
            : role === 'admin'
              ? orgOptions
              : [];

          setOrganizationsOptions(filteredOptions);

          // Fetch project details after setting organization options
          await fetchProjectDetails(filteredOptions);
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchOrganizationsAndProject();
  }, [role, userId, id]);


  const fetchProjectDetails = async (orgOptions) => {
    try {
      const response = await api.get(`/api/project/getProjectEditDetails/${id}`);
      const projectData = response.data.project;
      setProject(projectData);
      setCoverPhotoPreview(projectData.project_cover_image);
      setAddress(projectData.address);
      setLatlng({ lat: projectData.lat, lng: projectData.longi });
      
      const tags = projectData.tags ? projectData.tags.split(',') : [];
      setTags(tags);
      
      // Set ESG Goals
      const esgGoalsArray = projectData.esgGoals ? projectData.esgGoals.split(',') : [];
      const selectedGoals = esgGoalsArray
        .map(goal => esgOptions.find(option => option.value === goal))
        .filter(Boolean);
      setSelectedGoals(selectedGoals);

      // Set Organizations
      const companyIdsArray = projectData.companyIds ? projectData.companyIds.split(',') : [];
      const selectedOrgs = companyIdsArray
        .map(companyId => orgOptions.find(option => option.value.toString() === companyId))
        .filter(Boolean);
      setSelectedOrganizations(selectedOrgs);    
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };


  const handleChange = (e) => {
    if(e.target.name === "target"){
      const nodecimalregex = /^\d*$/; 
      if(nodecimalregex.test(e.target.value)){
        setProject({
          ...project,
          [e.target.name]: e.target.value,
        });
      }else{
        return;
      }
    }else{
      setProject({
        ...project,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCoverPicChange = (e) => {
    const file = e.target.files[0];
    setCoverPhoto(file);
    setCoverPhotoPreview(URL.createObjectURL(file));
  };

  const handleSave = async () => {
    if (!isLoggedIn) {
      toast.error("Please log in to create a project");
      setTimeout(() => {
        navigate("/login"); // Redirect to the login page after delay
      }, 1000);
      return;
    }

    const requiredFields = ['name', 'startDate', 'endDate', 'description', 'target', 'address', 'selectedOrganizations', 'selectedGoals', 'coverPhoto'];
    const invalids = [];
  
    // Check required fields
    if (!project.name) invalids.push("projectName");
    if (!project.startDate) invalids.push("startDate");
    if (!project.endDate) invalids.push("endDate");
    if (!project.description) invalids.push("Desc");
    if (!project.target) invalids.push("target");
    if (!address) invalids.push("address");
    if (selectedOrganizations.length === 0) invalids.push("Orgs");
    if (selectedGoals.length === 0) invalids.push("goals");
    if (!coverPhoto && !coverPhotoPreview) invalids.push("cover");
    if (tags.length === 0) invalids.push("tags");
  
    setInvalidFields(invalids);
  
    if (invalids.length > 0) {
      toast.error("Please fill in all required fields.");
      return; // Stop form submission if there are invalid fields
    }
  
    setIsSubmitting(true);
    const formData = new FormData();

    if (coverPhoto) {
      const compressedCover = await compressImage(coverPhoto);
      formData.append('projectCoverImage', compressedCover);  // Change field name
      formData.append('coverImageName', coverPhoto.name);
    }

    const sortedGoals = selectedGoals.sort((a, b) => a.index - b.index);

    // Append other project details
    Object.keys(project).forEach((key) => {
      formData.append(key, project[key]);
    });
    formData.set("lat", latlng.lat);
    formData.set("longi", latlng.lng);
    formData.append("city", addressData.city);
    formData.append("country", country);
    formData.append("state", addressData.state);
    formData.append("post_code",addressData.postcode);
    formData.set("address",address);
    formData.set("tags",tags);
    // formData.set("esgGoals",selectedGoals.map((val)=>val.value));
    formData.set("esgGoals", sortedGoals.map((val) => val.value));
    formData.set("companyIds",selectedOrganizations.map((val)=>val.value));
    formData.set("organizationName",selectedOrganizations.map((val)=>val.label));
    
    try {
      await api.post(`/api/project/updateProject/${project.project_id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Project updated successfully");
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating project.");
    }
    finally {
      setIsSubmitting(false);
      navigate(`/dashboard/projectdashboard/projectArea/${id}`)
    }
  };

  const onCancelClick = async()=>{
    setAddress("")
    setLatlng(null)
    navigate("/dashboard/projectdashboard")
  }

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  return (
    <div className="edit-project-page">
    <ToastContainer />
    <Dialog 
        open={modalConfig.isOpen} 
        onClose={closeModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {modalConfig.title}
          <IconButton
            onClick={closeModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {renderComponent()}
        </DialogContent>
      </Dialog>
    <div className="project-profile">
      <div className="cover-pic">
        <img
          src={coverPhotoPreview || TreePlantationCoverPic}
          alt="Cover Pic"
        />
        <input type="file" accept="image/*" onChange={handleCoverPicChange}  style={invalidFields.includes("cover") ? invalidFieldStyle : {}}/>
        <label>Project Cover Pic</label>
      </div>
    </div>
    <div className="project-details">
    <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
      <div className="form-row">
        <div className="input-container">
          <label htmlFor="projectName">Project Name <span style={{color:'red'}}>*</span></label>
          <input
            type="text"
            name="name"
            value={project.name || ""}
            onChange={handleChange}
            style={invalidFields.includes("projectName") ? invalidFieldStyle : {}}
            placeholder="Project Name"
          />
        </div>
        <div className="input-container">
          <label htmlFor="projectStartDate">Project Start Date <span style={{color:'red'}}>*</span></label>
          <input
            type="date"
            name="startDate"
            value={project.startDate || ""}
            onChange={handleChange}
            style={invalidFields.includes("startDate") ? invalidFieldStyle : {}}
          />
        </div>
        <div className="input-container">
          <label htmlFor="projectEndDate">Project End Date <span style={{color:'red'}}>*</span></label>
          <input
            type="date"
            name="endDate"
            value={project.endDate || ""}
            onChange={handleChange}
            style={invalidFields.includes("endDate") ? invalidFieldStyle : {}}
       
          />
        </div>
      </div>
      <div className="form-row">
        <div className="input-container">
          <label htmlFor="projectDescription">Project Description <span style={{color:'red'}}>*</span></label>
          <textarea
            name="description"
            value={project.description || ""}
            onChange={handleChange}
            placeholder="Project Description"
            style={invalidFields.includes("Desc") ? invalidFieldStyle : {}}
       
          />
        </div>
      </div>
      <div className="form-row">
        <div className="input-container">
          <label htmlFor="projectTreeTarget">Tree Target <span style={{color:'red'}}>*</span></label>
          <input
            type="number"
            name="target"
            value={project.target || ""}
            onChange={handleChange}
            placeholder="Target"
            style={invalidFields.includes("target") ? invalidFieldStyle : {}}
       
          />
        </div>
      <div className="input-container">
    <label>Organizations <span style={{color:'red'}}>*</span></label>
    <Select
      options={organizationsOptions}
      isMulti
      value={selectedOrganizations}
      onChange={(selectedOptions) => setSelectedOrganizations(selectedOptions)}
      styles={{
        container: (base) => ({
          ...base,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          minHeight: "50px",
          borderColor: "#ced4da",
          boxShadow: "none",
          "&:hover": {
            borderColor: "#adb5bd",
          },
          ...(invalidFields.includes("Orgs")
            ? invalidFieldStyle
            : {}),
        }),
        singleValue: (base) => ({
          ...base,
          color: "#495057",
        }),
        placeholder: (base) => ({
          ...base,
          color: "#6c757d",
        }),
      }}  />
  </div>

        <div className="input-container">
          <label htmlFor="projectESGGoals">Project ESG Goals <span style={{color:'red'}}>*</span></label>
          <Select
            options={esgOptions}
            isMulti
            value={selectedGoals}
            onChange={(selectedOptions) => setSelectedGoals(selectedOptions)}
            required
            styles={{
              container: (base) => ({
                ...base,
                width: "100%",
              }),
              control: (base) => ({
                ...base,
                minHeight: "50px",
                borderColor: "#ced4da",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#adb5bd",
                },
                ...(invalidFields.includes("goals")
                  ? invalidFieldStyle
                  : {}),
              }),
              singleValue: (base) => ({
                ...base,
                color: "#495057",
              }),
              placeholder: (base) => ({
                ...base,
                color: "#6c757d",
              }),
            }}
          />
        </div>
      </div>

      <div className="form-row">
                <div>
                  <label>
                    Tags <span style={{ color: "red" }}>*</span>
                  </label>
                  <div style={invalidFields.includes("tags") ? invalidFieldStyle : {}}>
                    <TagsInput value={tags} onChange={setTags} />
                  </div>
                </div>
                </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <label htmlFor="projectLocation">Project Location <span style={{color:'red'}}>*</span>&nbsp;&nbsp;&nbsp;&nbsp;</label>
        <input
          type="text"
          placeholder="Select Location From Map"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          readOnly
          style={{
            flex: "1",
            marginRight: "10px",
            ...(invalidFields.includes("address") ? invalidFieldStyle : {}),
          }}
          onClick={() => openModal("Select Location", PopupMap)}
          
        />
        <button
          type="button"
          className="fa-solid"
          onClick={() => openModal("Select Location", PopupMap)}
          style={{
            height: "60px",
            paddingRight: "55px",
            paddingLeft: "55px",
            marginBottom: "20px",
            backgroundColor: "#3E6294",
          }}
          tabIndex="3"
          disabled={isSubmitting}
        >
            📍
          </button>
        </div>
        <button disabled={isSubmitting} onClick={handleSave}> {isSubmitting ? (
    <ClipLoader color="white" size='20'/>
  ) : (
    <>
     Save Changes
     
    </>
  )}</button>
        <button disabled={isSubmitting} style={{backgroundColor:"#E70000"}} onClick={onCancelClick}>Cancel</button>
      </div>
    </div>
  );
};

export default EditProjectPage;
