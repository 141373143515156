import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from '../axios/api';
import '../assets/NewFiles/Css/LoginPage.css';
import { GlobalContext } from '../contexts/GlobalContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import { Eye, EyeOff } from 'lucide-react';

const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setLoggedIn, setProfilePicture, setRole, setUserId, setRedeemedPoints, setCollectedPoints } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { email, password } = data;

    // Normalize email to lowercase
    const userEmail = email.toLowerCase();

    try {
      const response = await api.post('/api/auth/signin', {
        userEmail,
        password,
      });

      if (response.status === 200) {
        toast.success('Login successful');

        ReactGA.event({
          category: 'User',
          action: 'Login Click',
          label: 'Login Form'
        });

        // Store the JWT token in local storage
        localStorage.setItem('token', response.data.token);

        setLoggedIn(true);
        setRole(response.data.user.role_code);
        setUserId(response.data.user.id);
        setProfilePicture(response.data.user.userProfilePic);
        setCollectedPoints(response.data.user.collectedPoints);
        setRedeemedPoints(response.data.user.redeemedPoints);

        if (response.data.user) {
          navigate('/dashboard/stats');
        } else {
          toast.error('Login failed. Please check your credentials and try again.');
        }
      } else {
        toast.error('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Login failed: ${error.response.data.message}`);
      } else {
        toast.error('Login failed: An unexpected error occurred.');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <p style={{ fontSize: '13px' }}>NOTE: fields marked with <span style={{ color: 'red' }}>*</span> are mandatory to input.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-form-group">
            <label>Email <span style={{ color: 'red' }}>*</span></label>
            <input
              type="email"
              {...register('email')}
              placeholder="Enter your email"
              style={errors.email ? invalidFieldStyle : {}}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>
          <div className="login-form-group">
            <label>
              Password <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                {...register("password")}
                placeholder="Enter your password"
                className={`w-full px-3 py-2 border rounded focus:outline-none ${errors.password ? "border-red-500" : "border-gray-300"
                  }`}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '30%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {showPassword ? <EyeOff size={20} color="#666" /> : <Eye size={20} color="#666" />}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>}
          </div>

          <button type="submit" className="login-button">Login</button>
        </form>
        <div className="login-options">
          <Link to="/forget-password" className="option-link">Forgot Password?</Link>
          <Link to="/signup" className="option-link">Sign Up</Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
