import React from 'react';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Tooltip } from 'react-tooltip';
import { FaMapMarkerAlt, FaCheckCircle, FaTree, FaUserAlt, FaCalendarPlus, FaPagelines, FaCalendarAlt, FaClock, FaInfoCircle } from 'react-icons/fa';
import { Marker, Popup } from 'react-leaflet';

const CustomClusterGroup = ({ trees, speciesData }) => {
  const createClusterCustomIcon = (cluster) => {
    const markers = cluster.getAllChildMarkers();
    const species = markers[0].options.species; // All markers in a cluster belong to the same species
    const count = markers.length;
    const { iconUrl } = markers[0].options.icon.options;

    return L.divIcon({
      html: `
        <div class="cluster-icon">
          <img src="${iconUrl}" alt="${species}" class="species-icon" />
          <div class="cluster-count-badge">${count}</div>
        </div>
      `,
      className: 'custom-cluster-icon',
      iconSize: L.point(32, 32)
    });
  };

  return (
    <MarkerClusterGroup
      chunkedLoading
      iconCreateFunction={createClusterCustomIcon}
      maxClusterRadius={60}
      spiderfyOnMaxZoom={true}
      showCoverageOnHover={false}
      zoomToBoundsOnClick={true}
      disableClusteringAtZoom={19}
      spiderfyDistanceMultiplier={2}
      spiderfyShapePositions={(count, center) => {
        // Prevent grouping by spreading markers in a circle
        const angleStep = (Math.PI * 2) / count;
        return Array.from({ length: count }, (_, i) => {
          const angle = i * angleStep;
          return L.latLng(
            center.lat + 0.0002 * Math.sin(angle),
            center.lng + 0.0002 * Math.cos(angle)
          );
        });
      }}
      clusterOptions={{
        // Ensure markers of different species are never clustered together
        chunkedLoading: true,
        chunkInterval: 100,
        chunkProgress: (processed, total) => { },
        maxClusterRadius: (zoom) => (zoom === 19 ? 1 : 60), // Disable clustering at max zoom
        spiderfyOnMaxZoom: true,
        disableClusteringAtZoom: 19,
        singleMarkerMode: true, // Treat each cluster as a single marker
      }}
    >
      {trees.map((tree) => (
        <Marker
          key={tree.tree_id}
          position={[tree.lat, tree.lng]}
          icon={new L.Icon({
            iconUrl: tree.species_icon_url,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32],
            className: 'tree-marker-icon',
          })}
          species={tree.tree_species_label} // Ensure species is passed as an option
        >
          <Popup>
            <div
              style={{
                display: "flex",
                minWidth: "400px",
                maxWidth: "600px",
                gap: "4px",
                padding: "0",
              }}
            >
              <div style={{ width: "40%" }}>
                <a
                  target="_blank"
                  className="search-more-info-btn"
                  href={`/plantprofile/${tree.tree_id}`}
                  rel="noreferrer"
                  data-tooltip-id="View-More-plantName"
                  data-tooltip-content="More Info"
                >
                  <img
                    src={tree.treeImageUrl}
                    alt={tree.imageName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "scale-down",
                      borderRadius: "4px",
                    }}
                  />
                </a>
              </div>
              <div
                style={{
                  width: "40%",
                  fontSize: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a
                    target="_blank"
                    className="search-more-info-btn"
                    href={`/plantprofile/${tree.tree_id}`}
                    rel="noreferrer"
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="More Info"
                  >
                    <p
                      style={{
                        margin: 0,
                        lineHeight: "1",
                      }}
                    >
                      {tree.treeName}
                    </p>
                  </a>
                  {tree.isVerified ? (
                    <FaCheckCircle
                      style={{
                        color: "#16a34a",
                        fontSize: "12px",
                      }}
                    />
                  ) : (
                    <FaCheckCircle
                      style={{
                        color: "yellow",
                        fontSize: "12px",
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "1px",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="TreeId"
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      <FaTree
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {tree.tree_id}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Uploaded By"
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      <FaUserAlt
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {tree.full_name}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Uploaded On"
                  >
                    <span style={{ fontWeight: 600, color: "black" }}>
                      <FaCalendarPlus
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {new Date(tree.createdAt).toLocaleDateString()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Planted On"
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      <FaCalendarAlt
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {new Date(tree.plantedOn).toLocaleDateString()}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Species"
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      <FaPagelines
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {tree.tree_species_label}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Characteristics"
                  >
                    <span style={{ fontWeight: 600, color: "black" }}>
                      <FaInfoCircle
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {tree.species_characteristics}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Age"
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      <FaClock
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {tree.age}
                  </p>

                  <p
                    style={{
                      margin: 0,
                      overflow: "hidden", // Prevents overflow
                      textOverflow: "ellipsis", // Adds ellipsis if the text overflows
                      display: "-webkit-box", // Ensures the flexbox container
                      WebkitBoxOrient: "vertical", // Sets the text to be vertical
                      WebkitLineClamp: 2, // Limits the lines before truncation, can adjust the number of lines
                      wordBreak: "break-word", // Breaks long words onto the next line
                      fontSize: "10px",
                      lineHeight: "1",
                      padding: "0",
                    }}
                    data-tooltip-id="View-More-plantName"
                    data-tooltip-content="Location"
                  >
                    <span style={{ fontWeight: 600, color: "black" }}>
                      <FaMapMarkerAlt
                        style={{
                          display: "inline-flex",
                          marginBottom: "2px",
                        }}
                        color={"#002D62"}
                        size={14}
                      />
                    </span>{" "}
                    {tree.address}
                  </p>
                </div>
              </div>
            </div>
            <Tooltip id="View-More-plantName" place="top" effect="solid" />
          </Popup>
        </Marker>
      ))}
    </MarkerClusterGroup>
  );
};

export default CustomClusterGroup;

// Add CSS styles
const styles = `
  .custom-cluster-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: transform 0.2s ease;
  }

  .cluster-icon {
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .species-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .species-icon-multi {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .species-icon-multi img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  .cluster-count-badge {
    position: absolute;
    bottom: -4px;
    right: -4px;
    background: #2196F3;
    color: white;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid white;
  }
`;

// Inject styles
const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);